import { render, staticRenderFns } from "./by-person.vue?vue&type=template&id=2d3e7e28&scoped=true&"
import script from "./by-person.vue?vue&type=script&lang=ts&"
export * from "./by-person.vue?vue&type=script&lang=ts&"
import style0 from "./by-person.vue?vue&type=style&index=0&id=2d3e7e28&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d3e7e28",
  null
  
)

export default component.exports