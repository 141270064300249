




















































































import Vue from 'vue'
import Reimbursement from '../../../../../store/models/Reimbursement'
import ReimbursementDeadline from '../../../../../store/models/ReimbursementDeadline'

export default Vue.extend({
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'N° transaction',
          field: 'transaction.reference'
        },
        {
          label: 'Payer pour',
          field: 'beneficiary.name'
        },
        {
          label: 'Payer par',
          field: 'payerPhone'
        },
        {
          label: 'Montant Remboursé',
          field: 'amount'
        },
        {
          label: 'Date',
          field: 'createdAt'
        },
        {
          label: 'Statut',
          field: 'state'
        }
        // {
        //   label: 'Actions',
        //   field: '_actions',
        //   align: 'left'
        // }
      ],

      echeances: [],

      activeDeadline: null
    }
  },

  computed: {
    beneficiaryGroupId(): string {
      return this.$route.params.beneficiaryGroupId
    },

    deadlines(): ReimbursementDeadline[] {
      return ReimbursementDeadline.byBeneficiaryGroup(this.beneficiaryGroupId)
    },

    reimbursements(this: any): Reimbursement[] {
      return this.activeDeadline
        ? Reimbursement.query()
            .where('reimbursementDeadlineId', this.activeDeadline.id)
            .with('transaction')
            .get()
        : []
    }
  },

  created(this: any) {
    this.loading = true

    if (this.deadlines.length > 0) {
      this.activeDeadline = this.deadlines[0]
    }

    Promise.all([
      ReimbursementDeadline.api().fetchByBeneficiaryGroup(
        this.beneficiaryGroupId
      ),
      Reimbursement.api().fetchByBeneficiaryGroup(this.beneficiaryGroupId)
    ])
      .then(() => {
        this.loading = false
        if (!this.activeDeadline) {
          this.activeDeadline = this.deadlines[0]
        }
      })
      .catch((error: Error) => {
        console.error(error)
        this.loading = false
      })
  },

  methods: {
    seeEcheance(this: any, deadline: ReimbursementDeadline) {
      // console.log(value)
      this.activeDeadline = deadline
    },

    onSeeDetails() {
      // TODO: Disbursments Details page
    }
  }
})
